/**
 *  Load modules
 */
import './plugins/jquery.fancybox';
import './plugins/jquery.timepicker';
import '../scss/app.scss';
jQuery(document).ready(function () {
  jQuery('.js-modal-open').fancybox();

  if (jQuery('.js-video-src').length) {
    // eslint-disable-next-line consistent-return
    jQuery('.js-video-src').on('click', function () {
      var videoId = jQuery(this).attr('attr-id-video');
      var videoSource = jQuery(this).attr('link-youtube-video');
      jQuery("#video-".concat(videoId)).append("<video id=\"".concat(videoId, "\" class=\"video-js vjs-default-skin vjs-fluid\" width=\"640\" height=\"264\"></video>"));

      if (jQuery("#".concat(videoId)).length) {
        // eslint-disable-next-line no-undef
        var myPlayer = videojs(videoId, {
          controls: true,
          techOrder: ['youtube'],
          width: 500,
          sources: [{
            type: 'video/youtube',
            src: videoSource
          }]
        });
        jQuery('.fancybox-close-small').on('click', function () {
          myPlayer.dispose();
          jQuery("#".concat(videoId)).remove();
        });
      } else {
        return false;
      }
    });
  }

  if (jQuery('.must-watch').length) {
    var mustWatch = videojs('must-watch', {
      controls: true
    });
    jQuery('.js-must-watch-play').on('click', function () {
      jQuery('.must-watch__video').addClass('play');
      mustWatch.play();

      if (jQuery('.must-watch__preview').hasClass('play')) {
        jQuery('.must-watch__preview').removeClass('play');
      } else {
        jQuery('.must-watch__preview').addClass('play');
      }
    });
  }

  if (jQuery('.schedule-form').length) {
    var curOffset = new Date().getTimezoneOffset() / 60;
    var utc = "UTC" + (curOffset > 0 ? "-" + curOffset : "+" + -curOffset);
    jQuery('.js-timezone input').val(utc);
    jQuery('.date-time-check li').each(function (i) {
      var index = ++i;
      jQuery('input[type=checkbox]').addClass('active');

      if (jQuery(this).children('input[type=checkbox]').attr('checked') == 'checked') {
        jQuery(this).children('input[type=checkbox]').removeAttr("checked");
        jQuery(this).append("<input class=\"js-date-time datatimepicker disabled\" data-index=\"time-".concat(index, "\" type=\"text\"/>"));
      }

      jQuery(this).children('input[type=checkbox]').on('click', function () {
        if (jQuery(this).prop('checked')) {
          jQuery(this).siblings('.js-date-time').removeClass('disabled');
          jQuery(this).siblings('.datatimepicker').trigger('focus');
        } else {
          jQuery(this).siblings('.js-date-time').addClass('disabled');
        }
      });
    });
    jQuery('.date-data input').each(function () {
      if (jQuery(this).val().length == 0) {
        jQuery(this).val('-');
      }
    });
    jQuery('.js-date-time').change(function () {
      var currentDate = jQuery(this);
      jQuery('.date-data input').each(function (i) {
        // eslint-disable-next-line no-plusplus,no-param-reassign
        var index = ++i;
        jQuery(this).attr('data-index', "time-".concat(index));

        if (jQuery(this).val().length == 0) {
          jQuery(this).val('-');
        }

        if (jQuery(this).attr('data-index') === currentDate.attr('data-index')) {
          jQuery(this).val(currentDate.val());
        }
      });
    });
  }

  jQuery('.close').on('click', function () {
    var videoURL = jQuery('.js-video-iframe').prop('src');
    videoURL = videoURL.replace("&autoplay=1", "");
    jQuery('.js-video-iframe').prop('src', '');
    jQuery('.js-video-iframe').prop('src', videoURL);
  });
});
jQuery(document).on('gform_post_render', function (event, form_id, current_page) {
  if (jQuery('.schedule-form').length) {
    var curOffset = new Date().getTimezoneOffset() / 60;
    var utc = "UTC" + (curOffset > 0 ? "-" + curOffset : "+" + -curOffset);
    jQuery('.js-timezone input').val(utc);
    jQuery('.date-time-check li').each(function (i) {
      var index = ++i;
      jQuery('input[type=checkbox]').addClass('active');

      if (jQuery(this).children('input[type=checkbox]').attr('checked') == 'checked') {
        jQuery(this).children('input[type=checkbox]').removeAttr("checked");
        jQuery(this).append("<input class=\"js-date-time datatimepicker disabled\" data-index=\"time-".concat(index, "\" type=\"text\"/>"));
      }

      jQuery(this).children('input[type=checkbox]').on('click', function () {
        if (jQuery(this).prop('checked')) {
          jQuery(this).siblings('.js-date-time').removeClass('disabled');
          jQuery(this).siblings('.datatimepicker').trigger('focus');
        } else {
          jQuery(this).siblings('.js-date-time').addClass('disabled');
        }
      });
    });
    jQuery('.date-data input').each(function () {
      if (jQuery(this).val().length == 0) {
        jQuery(this).val('-');
      }
    });
    jQuery('.js-date-time').change(function () {
      var currentDate = jQuery(this);
      jQuery('.date-data input').each(function (i) {
        // eslint-disable-next-line no-plusplus,no-param-reassign
        var index = ++i;
        jQuery(this).attr('data-index', "time-".concat(index));

        if (jQuery(this).val().length == 0) {
          jQuery(this).val('-');
        }

        if (jQuery(this).attr('data-index') === currentDate.attr('data-index')) {
          jQuery(this).val(currentDate.val());
        }
      });
    });
  }
});